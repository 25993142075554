/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:0b449bfe-828e-4d2f-9d9a-b03f6fb180f3",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_uHUBCNHsg",
    "aws_user_pools_web_client_id": "25bd4eanqtbder7n8tg78jkv4u",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_appsync_graphqlEndpoint": "https://gv5ez3gugbgarhgbjmbpvj3rrq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "tsbucket82154-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
