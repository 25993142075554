/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getExtconfig = /* GraphQL */ `
  query GetExtconfig($id: ID!) {
    getExtconfig(id: $id) {
      id
      tenant
      LogoURL
      ShowAdvancedSearch
      SidebarColor
      SidebarSelectionColor
      LightThemeLightColor
      LightThemeMainColor
      DarkThemeLightColor
      DarkThemeMainColor
      IsFirstRun
      Locations
      createdAt
      updatedAt
    }
  }
`;
export const listExtconfigs = /* GraphQL */ `
  query ListExtconfigs(
    $id: ID
    $filter: ModelExtconfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExtconfigs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tenant
        LogoURL
        ShowAdvancedSearch
        SidebarColor
        SidebarSelectionColor
        LightThemeLightColor
        LightThemeMainColor
        DarkThemeLightColor
        DarkThemeMainColor
        IsFirstRun
        Locations
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTagGroups = /* GraphQL */ `
  query GetTagGroups($id: ID!) {
    getTagGroups(id: $id) {
      id
      tenant
      replace
      tagGroups
      createdAt
      updatedAt
    }
  }
`;
export const listTagGroups = /* GraphQL */ `
  query ListTagGroups(
    $id: ID
    $filter: ModelTagGroupsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTagGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tenant
        replace
        tagGroups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
